<template>
    <div>
        <!-- <b-container v-if="project == null" class="mt-5">
            <h1>Could not find that project. It may have been deleted by the owner. Check your internet connection and try again.</h1>
        </b-container> -->
        <b-container>
            <b-row class="pt-3 pb-2">
                <b-col>
                    <h1>{{project.title}}</h1>
                    <h5 v-if="isMyProject">Your Project</h5>
                    <h5 @click="goToProfile" v-else>By {{project.creator}}</h5>
                    <h3><b-badge @click="goToCategory" variant="light" class="shadow-sm" :style="categoryBadgeStyle">{{getCategoryTitle(project.categoryId)}}</b-badge></h3>
                    <hr>
                    <button v-if="isMyProject" @click="editProject" type="button" class="btn parsillBlueBg font-white font-bold px-5 py-2">Edit Project</button>
                </b-col>
            </b-row>
            <div :class="imageDisplayClasses" style="background-color:white;" v-if="imgUrls != null && imgUrls.length > 0">
                <ImageCarouselCard class="mx-1" v-for="(url, index) in imgUrls" :key="index" :imgUrl="url" :monoImage="imgUrls.length == 1" :contentType="contentTypes[index]" @viewImage="viewImage"/>
            </div>
            <div v-else>
                <b-spinner></b-spinner>
            </div>
            <div class="d-flex flex-row justify-content-between w-100 px-5">
                <div style="height: 50px;" class="pt-3 mb-3">
                    <div v-if="reactions.length > 0" class="d-flex flex-row" @click="showReactions">
                        <div v-for="reaction in reactionDisplay" :key="reaction.icon" class="mx-1">
                            <b-icon  :icon="reaction.icon" style="width: 25px; height: 25px;"></b-icon>
                            {{reaction.count}}
                        </div>
                    </div>
                    <div v-else @click="showReactions">
                        <b-icon icon="heart-fill" style="width: 25px; height: 25px;"></b-icon>
                        +
                    </div>
                </div>

                <div style="height: 50px;" class="pt-3 mb-3">
                    <div v-if="isMyProject">
                        <b-icon icon="bookmark-heart-fill" style="width: 25px; height: 25px;"></b-icon>
                        {{projectSaves}}
                    </div>
                    <div v-else>
                        <b-icon v-if="hasBoosted" icon="arrow-up-circle-fill" variant="success" style="width: 25px; height: 25px;"></b-icon>
                        <b-icon @click="toggleBoost" v-else icon="arrow-up-circle" style="width: 25px; height: 25px;"></b-icon>
                    </div>
                </div>
            </div>
            <!-- <div class="">
                <div v-if="reactions.length > 0" class="d-flex flex-row justify-content-start" @click="showReactions">
                    <div class="icons-row" v-for="reaction in reactionDisplay" :key="reaction.id">
                        <h2 class="mx-1">
                            <emoji :icon="reaction.icon"/>
                        </h2>
                        {{reaction.count}}
                    </div>
                </div>
                <div v-else>
                    <div class="icons-row" @click="showReactions">
                        <div class="px-1">
                            <h2>
                                <emoji/>
                            </h2>
                        </div>
                        <div>
                            +
                        </div>
                    </div>
                </div>
                <div class="icons-row" v-if="isMyProject">
                    <h2 class="mx-1"><b-icon icon="bookmark-heart-fill"></b-icon></h2>
                    {{projectSaves}}
                </div>
                <div v-if="!isMyProject">
                    <h2>
                        <b-icon v-if="hasBoosted" icon="arrow-up-circle-fill" variant="success" class="w-25"></b-icon>
                        <b-icon v-else icon="arrow-up-circle" @click="toggleBoost" class="w-25"></b-icon>
                    </h2>
                </div>
            </div> -->
            <b-row class="px-1">
                <b-col v-if="project.buyProjectOn">
                    <button @click="goToPurchaseLink" type="button" class="btn parsillBlueBg font-white font-bold px-5 py-2 w-100">Buy</button>
                </b-col>
                <b-col v-if="project.howToLength > 0 || project.howToDescription != null">
                    <button @click="showHowTo" type="button" class="btn shadow font-white font-bold px-5 py-2 w-100" style="background-color:#0096FF">
                        <span v-if="showHowToDescription">Hide </span>
                        <span v-else>Show </span> 
                        How-To
                        </button>
                </b-col>
            </b-row>
            <b-row class="px-1" v-if="showHowToDescription">
                <b-col>
                    <div>
                        <p class="mt-3 mb-2" style="font-size:large;">{{project.howToDescription}}</p>
                    </div>
                    <div v-for="(url, index) in howToUrls" :key="url" class="my-3" @click="viewHowToMedia(index)">
                        <h5 style="color:blue; text-decoration:underline; cursor:pointer;">View File {{index}}</h5>
                    </div>
                </b-col>
            </b-row>
            <hr v-if="imgUrls.length < 3">
            <b-row class=" py-2">
                <p class="font-large">{{project.description}}</p>
            </b-row>
            <!-- <b-row class=" py-2 mb-2">
                <b-col><button>Share Project</button></b-col>
            </b-row> -->
            
            <!-- <b-row v-if="project.toolsUsed.length > 0" class="py-2 px-2">
                <h4 class="justify-content-start d-flex">Tools Used To Make:</h4>
            </b-row>
            <b-row v-if="project.toolsUsed.length > 0" class="px-2">
                <b-col  v-for="(tool, index) in project.toolsUsed" :key="index" >
                    <h4 v-if="toolLink(index)" class="d-flex justify-content-center"><b-badge variant="light" class="shadow-sm tool-link-blue-bg">{{tool}} <b-icon icon="link" class="white mx-1" @click="goToToolLink(index)"></b-icon></b-badge></h4>
                    <h5 v-else class="d-flex justify-content-center">{{tool}}</h5>
                </b-col>
            </b-row> -->
            <!-- <hr> -->
        </b-container>
        <reaction-dialog ref="reactionDialog"/>
    </div>
</template>

<script>
import ImageCarouselCard from './nested/ImageCarouselCard.vue';

import { mapState } from 'vuex';
import { db, fieldValue,  } from '../../db';
import ReactionDialog from './nested/ReactionDialog.vue';
// import Emoji from '../general/Emoji.vue';

export default {
    components: {
        ImageCarouselCard,
        ReactionDialog,
        // Emoji,
    },
    props: {
        project: null,
        imgUrls: [],
        contentTypes: [],
        reactions: {type: Array, default: () => []},
        isMyProject: {type: Boolean, default: false},
        howToUrls: [],
        howToTypes: [],
    },
    data() {
        return {
            showHowToDescription: false,
        }
    },
    computed: {
        projectSaves() {
            return this.project?.saves ?? 0;
        },
        hasBoosted() {
            if (this.isMyProject) return true;

            return (this.currentUser.boostedProjects ?? []).includes(this.project.id);
        },
        ...mapState({
            categories: (state) => state.categories,
            currentUser: (state) => state.parsillUser,
        }),
        categoryBadgeStyle() {
            const hex = this.categories.find((e) => e.id == this.project.categoryId).color;
            return `background-color:#${hex};`
        },
        imageDisplayClasses() {
            return `d-flex flex-row justify-content-${this.imgUrls.length > 1 ? "start" : "center"} image-display`;
        },
        reactionDisplay() {
            let totals = {};

            for (let i=0; i < this.reactions.length; i++) {
                const reaction = this.reactions[i];
                console.log(reaction);
                if (totals[reaction.icon] == null) totals[reaction.icon] = 1;
                else totals[reaction.icon] += 1;
            }

            console.log(totals);

            let display = [];
            for (let key in totals) {
                display.push({
                    icon: `${key}`,
                    count: totals[key]
                });
            }

            console.log(display);

            return display;
        }
    },
    mounted() {
        this.$store.commit("updateTemporaryGallery", {imgUrls: null, contentTypes: null});
    },
    methods: {
        async showReactions() {

            if (this.isMyProject) return;
            
            const reactionResult = await this.$refs.reactionDialog.show({
                title: `React to ${this.project.title}`,
            });

            const projectRef = db.collection("projects").doc(this.project.id);

            let reactionData = {
                "name": reactionResult.name,
                "icon": reactionResult.icon,
                "creator": this.currentUser.id,
                "projectId": this.project.id,
            };

            try {
                await projectRef.collection("reactions").doc(this.currentUser.id).set(reactionData); // iding the doc with the current user ensures each comment can only have one reaction from each user

                console.log("added reaction");

                this.$emit("reloadReactions");
            }
            catch (err) {
                console.log(err);
                alert("Error reacting to comment");
            }
        },
        toggleBoost() {
            const userRef = db.collection("users").doc(this.currentUser.id);
            const projectRef = db.collection("projects").doc(this.project.id);
            let batch = db.batch();
            
            batch.update(userRef, {
                boostedProjects: fieldValue.arrayUnion(this.project.id),
            });

            batch.update(projectRef, {
                boosts: fieldValue.increment(1)
            });

            batch.commit()
            .then(() => {
                console.log("successful boost");
                this.$store.commit("updateFeaturedUrls", {
                    categoryId: this.project.categoryId,
                    url: null,
                });
            })
            .catch((err) => {
                console.log(err);
                alert("Something went wrong boosting the project.");
            });
        },
        getCategoryTitle(id) {
            if (this.categories == null) return "";
            if (this.categories.length == 0) return "";
            return this.categories.find(e => e.id == id)?.title ?? "";
        },
        viewImage(url) {
            this.$router.push({name: "View Project Image", params: {url: url}});
        },
        goToCategory() {
            this.$router.push(`/${this.project.categoryId}`);
        },
        goToProfile() {
            this.$router.push(`/viewprofile/${this.project.creatorId}`);
        },
        goToToolLink(index) {
            var shouldNavigate = confirm("This will take you to another website. Continue?");

            if (!shouldNavigate) return;

            var url = this.toolLink(index);

            if (url) {
                if (url.indexOf("http") == -1) {
                    url = "http://" + url;
                } 

                window.location.href = url;
            }
        },
        toolLink(index) {
            if (this.project.toolLinks == null) {
                return "";
            }
            if (index >= this.project.toolLinks.length) {
                return "";
            }
            var link = this.project.toolLinks[index];
            if (link == "") return null;
            return link;
        },
        goToPurchaseLink() {

            var shouldNavigate = confirm("This will take you to another website. Continue?");

            if (!shouldNavigate) return;

            var url = this.project.purchaseLink;

            if (url == null) {
                alert("No link associated with this project.");
                return;
            }

            
            if (url.indexOf("http") == -1) {
                url = "http://" + url;
            } 

            window.location.href = url;
        },
        async viewHowToMedia(index) {
            const url = this.howToUrls[index];
            const fileType = this.howToTypes[index];

            if (fileType.includes("pdf")) {
                // custom pdf viewer
                this.$router.push({name: "View PDF", params: {url: url}});
            }
            else {
                this.$router.push({name: "View Project Image", params: {url: url}});
            }
        },
        async showHowTo() {
            // first check if there is a description to display

            this.showHowToDescription = !this.showHowToDescription;


            // if (this.project.howToLength > 0 && (this.showHowToDescription || this.project.howToDescription == null)) {
            //     const shouldNavigate = confirm("This will open a PDF file. Continue?");

            //     if (!shouldNavigate) return;

            //     const howToRef = storage.ref(`/projects/${this.project.id}/howTo`);

            //     howToRef.getDownloadURL()
            //     .then((url) => {
            //         setTimeout(() => {
            //           window.open(url);  
            //           window.open(url);
            //         },);
            //     })
            //     .catch((err) => {
            //         console.log(err);
            //         alert("Error accessing media");
            //     });
            // }
        },
        shareProject() { 
            this.$emit("shareProject");
        },
        editProject() {
            this.$router.push({name: "Edit Project", params: {project: this.project}});
        },
        goToGallery() {
            this.$router.push({name: "Project Gallery", params: {imgUrls: this.imgUrls, contentTypes: this.contentTypes,}});
        }
    }
}
</script>

<style scoped>
    .font-large {
        font-size: 1.2em;
    }

    .purchase-button {
        border-color: #3F784C !important;
        background-color: #3F784C !important;
        font-weight: bold;
    }

    .purchase-button:hover {
        background-color: #3F784C;
    }

    .purchase-button:visited {
        background-color: #3F784C;
    }

    .tool-link-blue-bg {
        background-color:steelblue;
    }

    .font-white {
        color: white;
    }

    .font-bold {
        font-weight: bold;
    }

    .icons-row {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

    .bg-red {
        background-color: red;
    }

    .image-display {
        overflow: auto;
        white-space: nowrap;
    }
</style>