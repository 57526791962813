<template>
    <div class="no-overflow">
        <AppbarFixed :hasBackButton="true" :actionBootstrapIcon="actionIcon" @actionClick="saveProject" :hasShare="true" @shareProject="shareProject"/>

        <div>
            <ProjectOverview :project="project" 
            :imgUrls="imgUrls" :contentTypes="contentTypes" 
            :howToUrls="howToUrls" :howToTypes="howToTypes"
            :isMyProject="isMyProject" 
            :reactions="reactions" 
            @shareProject="shareProject" 
            @reloadReactions="loadReactions"/>
            <ProjectComments v-if="project != null" :projectId="projectId" :isMyProject="isMyProject"/>
        </div>
        <div class="bottom-margin-box"></div>
        <BottomNavBar :currentIndex="0" @updateIndex="updateIndex"/>
    </div>
</template>

<script>
import AppbarFixed from '../../components/general/AppbarFixed.vue';
import ProjectOverview from "../../components/project_view/ProjectOverview.vue";
import ProjectComments from "../../components/project_view/ProjectComments.vue";
import BottomNavBar from '../../components/general/BottomNavBar.vue';

import { db, fieldValue, storage } from '../../db';

import { mapState } from 'vuex';

export default {
    props: {
    },
    components: {
        AppbarFixed,
        ProjectOverview,
        ProjectComments,
        BottomNavBar
    },
    computed: {
        projectId: function() {
            return this.$route.params.id;
        },
        ...mapState({
            parsillUser: (state) => state.parsillUser,
            
        }),
        actionIcon: function() {
            if (this.project == null) return null;
            if (this.parsillUser == null) return null;
            if (this.parsillUser.id == this.project.creatorId) return null;
            
            if (this.parsillUser.savedProjects == null) return "bookmark";

            var saved = this.projectSaved(this.projectId);
            if (saved) return "bookmark-fill";
            else return "bookmark";
        },
        isMyProject: function () {
            if (this.parsillUser == null || this.project == null) return false;
            return this.parsillUser.id == this.project.creatorId;
        },
        webShareApiSupported() {
            return navigator.share
        },
    },
    data() {
        return {
            project: null,
            loading: true,
            imgUrls: [],
            contentTypes: [], //corresponds index-wise with imgUrls ^
            localSaves: null,
            reactions: {},
            howToUrls: [],
            howToTypes: [], //corresponds index-wise with howToUrls ^
        }
    },
    methods: {
        shareProject() { 
            if (this.webShareApiSupported) {
                navigator.share({
                    title: 'Check out this project on Parsill',
                    url: window.location.pathname,
                });
            } else {
                alert("Your browser does not support web sharing. We suggest you copy the url above, or use your browser's inbuilt share feature to send this link to someone.")
            }
        },
        updateIndex(index) {
            if (index == 4) {
                // means its create project
                this.$router.push("/create/project");
                index = 1;
            } else {
                this.$router.push("/");
            }
            this.$store.commit("updateTabIndex", index);
        },
        reloadReactions() {
            
        },
        loadReactions() {
            db
            .collection("projects")
            .doc(this.projectId)
            .collection("reactions")
            .get()
            .then((querysnap) => {
                if (querysnap.empty) return;

                this.reactions = querysnap.docs.map((e) => {
                    let data = e.data();
                    data.id = e.id;
                    return data;
                });
            })
            .catch((err) => {
                console.log(err);
                alert("Something went wrong loading reactions");
            })
        },
        loadProject() {
            db
            .collection("projects")
            .doc(this.projectId)
            .get()
            .then(snapshot => {
                this.project = snapshot.data();
                this.project.id = snapshot.id;
                this.loadImages();
                this.loadReactions();
                this.loadHowTos();
            })
            .catch(err => {
                this.loading = false;
                console.log(err);
            })
        },
        loadHowTos() {
            storage.ref().child("projects/" + this.project.id + "/howTo/").listAll()
            .then((result) => {
                for (var i=0; i < result.items.length; i++) {
                    var subRef = result.items[i];
                    subRef.getDownloadURL().then((subUrl) => {
                        subRef.getMetadata().then((metadata) => {
                            console.log(`metadata type: ${metadata.contentType}`);
                            this.howToUrls.push(subUrl);
                            this.howToTypes.push(metadata.contentType);
                            this.loading = false;
                        })
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                alert("Something went wrong loading project media.");
            });
        },
        async loadImages() {
            console.log("hello");
            console.log(this.project.media)
            if (this.project.media?.length == 0 ?? false) {
                console.log(this.project.media);
                storage.ref("projects/" + this.project.id + "/main")
                .getDownloadURL()
                .then((url) => {
                    console.log(url);
                    this.imgUrls.push(url);
                    this.contentTypes.push("image/");
                    this.loading = false;
                })
                .catch((err) => {
                    alert("Something went wrong loading the project image");
                    console.log(err);
                    this.loading = false;
                });

                return;
            }

            try {
                const result = await storage.ref().child("projects/" + this.project.id + "/other/").listAll()
                for (var i=0; i < result.items.length; i++) {
                    var subRef = result.items[i];
                    console.log(subRef.fullPath);
                    const subUrl = await subRef.getDownloadURL();
                    const metadata = await subRef.getMetadata();
                    console.log(`metadata type for subRef ${subRef.fullPath}: ${metadata.contentType}`);
                    this.imgUrls.push(subUrl);
                    this.contentTypes.push(metadata.contentType);
                    this.loading = false;
                    console.log(this.contentTypes);
                }
            } catch (err) {
                console.log(err);
                alert("Something went wrong loading project media.");
            }
        },
        saveProject() {
            var savedProjects = this.parsillUser.savedProjects ?? [];
            // add the project to your saved
            var isSaved = this.projectSaved(this.projectId);

            if (isSaved) {
                // means they are unsaving it
                for (var i=0; i < savedProjects.length; i++) {
                    if (savedProjects[i] == this.project.id) {
                        savedProjects.splice(i, 1);
                        break;
                    }
                }
            } else {
                // means they are saving it
                savedProjects.push(this.project.id);
            }

            let batch = db.batch();

            const userRef = db.collection("users").doc(this.parsillUser.id);
            batch.update(userRef, {
                savedProjects: savedProjects,
            });

            const projectRef = db.collection("projects").doc(this.projectId);
            batch.update(projectRef, {
                saves: fieldValue.increment(1),
            });

            batch.commit()
            .then(() => {
                console.log("success");
            }).catch((err) => {
                console.log(err);
                alert("Error saving project");
            })
        },
        projectSaved(id) {
            if (this.parsillUser.savedProjects == null) return false;

            

            for (var i=0; i < this.parsillUser.savedProjects.length; i++) {
                var iterId = this.parsillUser.savedProjects[i];
                if (iterId == id) return true;
            }

            return false;
        }
    },
    beforeMount () {
        this.loadProject();
    }
}
</script>

<style scoped>
    .borderBlack {
        border: 3px solid black;
    }

    .fontLarge {
        font-size: 1.2em;
    }
</style>