<template>
    <div class="mb-5">
        <b-container>
            <b-row class="py-3 mt-2">
                <h4 class="justify-content-start d-flex mx-2">Comments</h4>
            </b-row>
            <b-form class="mt-1" @submit.prevent="onSubmit">
                <b-form-group id="comment-input">
                    <b-form-input
                        id="input-1"
                        v-model="form.comment"
                        type="comment"
                        placeholder="Add comment"
                        size="lg"
                    >
                    </b-form-input>
                </b-form-group>
            </b-form>
            <hr>
            <b-container v-if="loading">
                <b-row>
                    <b-col></b-col>
                    <b-col><b-spinner variant="primary"></b-spinner></b-col>
                    <b-col></b-col>
                </b-row>
            </b-container>
            <b-container v-if="!loading && comments.length === 0">
                <b-row>
                    <h6>No Comments Yet</h6>
                </b-row>
            </b-container>
            <div v-if="!loading && comments.length > 0">
                <b-list-group v-for="(comment, index) in comments" v-bind:key="index">
                    <b-list-group-item class="my-1" >
                        <div class="d-flex w-100 justify-content-between align-items-center">
                            <span class="mx-2 bold" style="text-align: left">{{comment.poster}}</span>
                            
                            <span v-if="canDeleteComment(comment)" class="mx-2 bold" style="text-align: left; color: red" @click="deleteComment(comment)">Delete</span>
                        </div>
                        <div class="d-flex w-100 justify-content-start align-items-center">
                                <span class="mx-2 my-2" style="text-align: left">{{comment.text}}</span>
                            </div>
                        <div class="d-flex w-100 justify-content-start align-items-center">
                            <span class="mx-2 gray" style="text-align: left">{{formattedRecencyDate(dateFromTimestamp(comment.posted))}}</span>
                            |
                            <!-- <span class="mx-2" style="text-align: left">Reply</span> -->
                            <h5 @click="reply(comment)"><b-badge variant="light" class="shadow-sm parsillBlueBg mx-2 mt-2">Reply</b-badge></h5>
                            <h5 @click="showReactions(comment)" class="ml-auto mt-1"><b-icon icon="heart"></b-icon></h5>+
                        </div>
                        <div v-if="commentReactions[comment.id]" class="d-flex flex-row justify-content-start">
                            <div style="height: 50px;" class="pt-3 mb-3">
                                <div class="d-flex flex-row" @click="showReactions">
                                    <div v-for="reaction in reactionDisplay(comment)" :key="reaction.icon" class="mx-1">
                                        <b-icon  :icon="reaction.icon" style="width: 25px; height: 25px;"></b-icon>
                                        {{reaction.count}}
                                    </div>
                                </div>
                            </div>

                            
                        </div>
                        <!-- <div v-if="commentReactions[comment.id]" class="d-flex flex-row justify-content-start px-2 mt-2">
                            <div v-for="reactionType in reactionDisplay(comment)" :key="reactionType.icon" class="d-flex flex-row mx-1">
                                <h3>
                                </h3>
                                {{reactionType.count}}
                            </div>
                        </div> -->
                    </b-list-group-item>
                    
                    <!-- Comment replies -->
                    <div class="comment-reply" v-if="commentReplies[comment.id]">
                        <b-list-group-item class="my-2" v-for="reply in commentReplies[comment.id]" :key="reply.id">
                            <div class="d-flex w-100 justify-content-between align-items-center">
                                <span class="mx-2 bold" style="text-align: left">{{reply.poster}}</span>

                                <span v-if="canDeleteComment(reply)" class="mx-2 bold" style="text-align: left; color: red" @click="deleteComment(reply)">Delete</span>
                            </div>
                            <div class="d-flex w-100 justify-content-start align-items-center">
                                <span class="mx-2 my-2" style="text-align: left">{{reply.text}}</span>
                            </div>
                            
                            <div class="d-flex w-100 justify-content-start align-items-center">
                                <span class="mx-2 gray" style="text-align: left">{{formattedRecencyDate(dateFromTimestamp(reply.posted))}}</span>
                               
                                <!-- <span class="mx-2" style="text-align: left">Reply</span> -->
                                <!-- <h5 @click="reply(reply)"><b-badge variant="light" class="shadow-sm parsillBlueBg mx-2 mt-2">Reply</b-badge></h5> -->
                                <h5 @click="showReactions(reply)" class="ml-auto mt-1"><b-icon icon="heart"></b-icon></h5>
                            </div>
                            <div v-if="commentReactions[reply.id]" class="d-flex flex-row justify-content-start px-2 mt-2">
                                <div v-for="reactionType in reactionDisplay(reply)" :key="reactionType.icon" class="d-flex flex-row mx-1">
                                    <h3><b-icon :icon="reactionType.icon"></b-icon></h3>
                                    {{reactionType.count}}
                                </div>
                            </div>
                        </b-list-group-item>
                    </div>
                </b-list-group>
            </div>
            
        </b-container>
        <reaction-dialog ref="reactionDialog"/>
        <confirmation-dialog  ref="confirmDialog" />
    </div>
</template>

<script>
import { db } from '../../db';
import { mapState } from 'vuex';
import ReactionDialog from './nested/ReactionDialog.vue';
import ConfirmationDialog from '../general/ConfirmationDialog.vue';

export default {
    components: { ReactionDialog, ConfirmationDialog, }, 
    props: {
        projectId: null,
        isMyProject: {type: Boolean, default: false},
    },
    data() {
        return {
            loading: true,
            comments: [],
            commentReplies: {},
            commentReactions: {},
            form: {
                comment: null,
                replyTarget: null,
            }
        }
    },
    computed: {
        ...mapState({
            parsillUser: (state) => state.parsillUser,
        }),
    },
    methods: {
        reactionDisplay(comment) {
            let totals = {};

            for (let i=0; i < this.commentReactions[comment.id].length; i++) {
                const reaction = this.commentReactions[comment.id][i];
                console.log(reaction);
                if (totals[reaction.icon] == null) totals[reaction.icon] = 1;
                else totals[reaction.icon] += 1;
            }

            console.log(totals);

            let display = [];
            for (let key in totals) {
                display.push({
                    icon: key,
                    count: totals[key]
                });
            }

            console.log(display);

            return display;
        },
        async showReactions(comment) {
            
            const reactionResult = await this.$refs.reactionDialog.show({
                title: `React to ${comment.poster}'s comment`,
            });

            const commentRef = db.collection("comments").doc(comment.id);

            let reactionData = {
                "name": reactionResult.name,
                "icon": reactionResult.icon,
                "creator": this.parsillUser.id,
                "commentId": comment.id,
                "projectId": this.projectId,
            };

            try {
                await commentRef.collection("reactions").doc(this.parsillUser.id).set(reactionData); // this ensures each comment can only have one reaction from each user

                console.log("added reaction");
                
                this.loadComments();
            }
            catch (err) {
                console.log(err);
                alert("Error reacting to comment");
            }
        },
        canDeleteComment(comment) {
            return this.isMyProject || comment.posterId == this.parsillUser.id;
        },
        async deleteComment(comment) {
            const shouldDelete = await this.$refs.confirmDialog.show({
                title: "Permanently delete this comment?",
                confirmPhrase: "Delete",
            });

            if (!shouldDelete) return;

            db.collection("comments").doc(comment.id).delete()
            .then(() => {
                console.log("Successfully deleted the comment!");
                this.comments = this.comments.filter((item) => item.id != comment.id);
                if (comment.replyTarget != null) {
                    this.commentReplies[comment.replyTarget] = this.commentReplies[comment.replyTarget].filter((item) => item.id != comment.id);
                }
            })
            .catch((err) => {
                console.log(err);
                alert(`Error deleting comment, please try again.`);
            })
        },
        reply(comment) {
            this.replyTarget = comment;
            var target = comment.poster;

            this.form.comment = `@${target} `;
            document.getElementById("input-1").focus();
        },
        async loadComments(showLoading = true) {
            if (showLoading) this.loading = true;
            else {
                alert("don't show loading");
            }

            try {
                const querysnap = await db
                .collection("comments")
                .where("projectId", "==", this.projectId)
                .orderBy("posted")
                .get();

                this.comments = querysnap.docs.map(doc => {
                    var data = doc.data();
                    data.id = doc.id;
                    return data;
                });

                this.commentReplies = {};

                for (let i=0; i < this.comments.length; i++) {
                    const comment = this.comments[i];

                    if (comment.replyTarget != null) {
                        const id = comment.replyTarget;
                        if (this.commentReplies[id] == null) {
                            this.commentReplies[id] = [comment];
                        } else {
                            this.commentReplies[id].push(comment);
                            this.commentReplies[id].sort((reply) => reply.date)
                        }
                    }
                }

                this.comments = this.comments.filter((comment) => comment.replyTarget == null);

                console.log(this.comments.map((e) => e.id));
            }
            catch (err) {
                console.log(err);
                alert("Error loading comments");
                this.loading = false;
                return;
            }

            // now load reactions
            try {
                this.commentReactions = {};
                const reactionsSnap = await db.collectionGroup("reactions").where("projectId", "==", this.projectId).get();

                console.log(`reactionsSnap.docs.length = ${reactionsSnap.docs.length}`);

                for (let i=0; i < reactionsSnap.docs.length; i++) {
                    const doc = reactionsSnap.docs[i];
                    let reaction = doc.data();
                    reaction.id = doc.id;
                    const commentId = reaction.commentId;

                    console.log(`found reaction ${reaction.name} for comment ${commentId}`);

                    if (this.commentReactions[commentId] == null) {
                        this.commentReactions[commentId] = [reaction];
                    }
                    else {
                        this.commentReactions[commentId].push(reaction);
                        this.commentReactions[commentId].sort((reaction) => reaction.name);
                    }
                }

            } catch (err) {
                console.log(err);
                alert("Error loading comment reactions");
            }

            this.loading = false;
            
        },
        onSubmit() {
            if (this.parsillUser.username == null) {
                alert("Complete your profile to start commenting!");
                return;
            }

            // var commentData = {
                
            // };

            // if (this.replyTarget.id ?? null) {
            //     commentData += {
            //         replyTarget: this.replyTarget.id,
            //     }
            // }

            db.collection("comments")
            .add({
                text: this.form.comment,
                projectId: this.projectId,
                posted: new Date(),
                posterId: this.parsillUser.id,
                poster: this.parsillUser.username,
                replyTarget: this.replyTarget?.id ?? null,
            })
            .then(() => {
                this.form.comment = null;
                this.form.replyTarget = null;
                this.loadComments();
            })
            .catch((err) => {
                alert(`Error uploading comment, please try again: ${err}`)
            });
        },
        dateFromTimestamp(timestamp) {
            return new Date(timestamp.seconds * 1000);
        },
        formattedRecencyDate(date) {
            let year = date.getFullYear();
            let month = date.getMonth() + 1;
            let day = date.getDate();
            let weekday = date.getDay();

            var hour = date.getHours();
            var minute = date.getMinutes();

            var minuteString = `${minute}`;

            var aa = "PM";

            if (hour < 12 || hour == 24) {
                aa = "AM";
            }

            if (hour > 12) {
                hour = hour - 12;
            }

            if (minute < 10) {
                minuteString = "0" + minuteString;
            }

            const currentDate = new Date();
            let currentYear = currentDate.getFullYear();
            let currentMonth = currentDate.getMonth() + 1;
            let currentDay = currentDate.getDate();

            console.log(currentMonth == month);
            console.log(currentYear == year);
            console.log(`${day} vs ${currentDay}`);

            // first check if it's the same year and month
            if (currentMonth == month && currentYear == year) {

                // if it was today, return time
                if (currentDay == day) {
                    return `${hour}:${minuteString} ${aa}`;
                }

                // if it was yesterday, say that
                if (currentDay - 1 == day) {
                    return `Yesterday at ${hour}:${minuteString} ${aa}`
                }

                // if it was this week, say that
                if (currentDay - day < 7) {
                    return `${this.dayFromDayNum(weekday)} at  ${hour}:${minuteString} ${aa}`;
                }
                
            } 

            // otherwise, just show the date
            return `${month}/${day}/${year}`;

        },
        dayFromDayNum(dayNum) {
            return ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"][dayNum];
        }
    },
    beforeMount() {
        this.loadComments();
    },
}
</script>

<style scoped>
    .gray {
        color: gray;
    }

    .bold {
        font-weight: 600;
    }

    .comment-reply {
        margin-left: 10vw;
    }

    .ml-auto {
        margin-left: auto;
    }

</style>