<template>
    <popup-modal ref="popup">
        <b-container>
            <b-row align-h="start">
                <b-col class="text-left">
                    <h4>{{title}}</h4>
                </b-col>
            </b-row>
            <b-row v-if="message">
                <b-col class="text-left">
                    {{message}}
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-button :class="buttonClasses(false)" block @click="_cancel">{{denyPhrase}}</b-button>
                </b-col>
                <b-col>
                    <b-button :class="buttonClasses(true)" block @click="_confirm">{{confirmPhrase}}</b-button>
                </b-col>
            </b-row>
        </b-container>
    </popup-modal>
</template>

<script>
import PopupModal from './PopupModal.vue'
export default {
  components: { PopupModal },
    data() {
        return {
            title: "Are you sure?",
            message: null,
            confirmPhrase: "Yes",
            denyPhrase: "Cancel",
            isConfirmDangerous: true, // handles special coloration of the buttons

            // private variables
            resolvePromise: null,
            rejectPromise: null,
        }
    },
    methods: {
        buttonClasses(isConfirmButton) {
            let classes = "mt-3";
            if (isConfirmButton) {
                if (this.isConfirmDangerous) classes += " negative-button";
                else classes += " positive-button";
            }
            else {
                if (this.isConfirmDangerous) classes += " positive-button";
                else classes += " negative-button";
            }

            return classes;
        },
        show(options = {}) {
            if (options.title) this.title = options.title;
            if (options.message) this.message = options.message;
            if (options.confirmPhrase) this.confirmPhrase = options.confirmPhrase;
            if (options.denyPhrase) this.denyPhrase = options.denyPhrase;
            if (options.isConfirmDangerous != null) this.isConfirmDangerous = options.isConfirmDangerous;

            this.$refs.popup.open();

            return new Promise((resolve, reject) => {
                this.resolvePromise = resolve
                this.rejectPromise = reject
            });
        },

        _confirm() {
            this.$refs.popup.close()
            this.resolvePromise(true)
        },

        _cancel() {
            this.$refs.popup.close()
            this.resolvePromise(false)
            // Or you can throw an error
            // this.rejectPromise(new Error('User cancelled the dialogue'))
        },
    }
}
</script>

<style scoped>

    .negative-button { 
        background-color: #D90429 !important;   
        border: #D90429 !important;   
        color: white !important;
    }

    .positive-button {
        background-color: #333333 !important;   
        border: #333333 !important;   
        color: white !important;
    }
</style>