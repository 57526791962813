<template>
    <popup-modal ref="popup">
        <div>
            <div class="d-flex flex-row justify-content-start">
                <h4>{{title}}</h4>
            </div>
            <div class="emoji-row mt-2">
                <div v-for="reaction in reactions" :key="reaction.name" @click="_select(reaction)">
                    <h1><b-icon :icon="reaction.icon"/></h1>
                </div>
            </div>
        </div>
    </popup-modal>
</template>

<script>
// import Emoji from '../../general/Emoji.vue';
import PopupModal from '../../general/PopupModal.vue';
export default {
  components: { PopupModal, },
    data() {
            return {
            title: "Are you sure?",
            message: null,

            // private variables
            resolvePromise: null,
            rejectPromise: null,

            reactions: [
                {
                    "name": "heart",
                    "icon": "heart",
                },
                {
                    "name": "laugh",
                    "icon": "emoji-laughing",
                },
                {
                    "name": "cool",
                    "icon": "emoji-sunglasses",
                },
                {
                    "name": "sad",
                    "icon": "emoji-frown",
                },
                {
                    "name": "angry",
                    "icon": "emoji-angry",
                },
                {
                    "name": "thumbs-up",
                    "icon": "hand-thumbs-up",
                },
            ]
        }
    },
    methods: {
        buttonClasses(isConfirmButton) {
            let classes = "mt-3";
            if (isConfirmButton) {
                if (this.isConfirmDangerous) classes += " negative-button";
                else classes += " positive-button";
            }
            else {
                if (this.isConfirmDangerous) classes += " positive-button";
                else classes += " negative-button";
            }

            return classes;
        },
        show(options = {}) {
            if (options.title) this.title = options.title;

            this.$refs.popup.open();

            return new Promise((resolve, reject) => {
                this.resolvePromise = resolve
                this.rejectPromise = reject
            });
        },
        _select(reaction) {
            this.$refs.popup.close();
            this.resolvePromise(reaction);
        }
    }
}
</script>

<style scoped>

    /* .negative-button { 
        background-color: #D90429 !important;   
        border: #D90429 !important;   
        color: white !important;
    }

    .positive-button {
        background-color: #333333 !important;   
        border: #333333 !important;   
        color: white !important;
    } */
    .emoji-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
</style>